import Api from "@/services/Index";

const store = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/exam/definitions", formData);
};

const calculate = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/exam/scores/calculate/" + id);
};
const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/exam/definitions", config);
};
const get = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/exam/definitions/" + id);
};
const put = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put("/exam/definitions/" + id, formData);
};
const deletes = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete("/exam/definitions/" + id);
};

const getStudents = async (sectionId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get(`sections/${sectionId}/students`);
};

const examScores = async (examId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get(`exam/scores?exam_id=${examId}`);
};

const examScoreSave = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post(`exam/scores`, data);
};

const examPublish = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get(`exams/publish/` + id);
};

export default {
    store,
    getAll,
    get,
    put,
    deletes,
    getStudents,
    examScores,
    examScoreSave,
    examPublish,
    calculate,
};
